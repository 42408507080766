define("riksdagen/templates/member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VivV/Frc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"member-profile\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"back-to-root\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"members\"]],{\"statements\":[[1,[28,\"material-icon\",[\"chevron_left\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[28,\"star-member\",null,[[\"member\"],[[24,[\"model\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"header\",true],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[24,[\"model\",\"thumbnail\"]]],[8],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"member-name\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[1,[24,[\"model\",\"firstName\"]],false],[0,\" \"],[1,[24,[\"model\",\"lastName\"]],false],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"member-party\"],[8],[1,[24,[\"model\",\"party\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"member-content\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"member-menu\"],[8],[0,\"\\n\\n      \"],[4,\"link-to\",null,[[\"class\",\"replace\",\"route\",\"model\"],[\"member-menu-item\",true,\"info\",[24,[\"model\",\"id\"]]]],{\"statements\":[[0,\"Biografi\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"link-to\",null,[[\"class\",\"replace\",\"route\",\"model\"],[\"member-menu-item\",true,\"votes\",[24,[\"model\",\"id\"]]]],{\"statements\":[[0,\"Voteringar\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"link-to\",null,[[\"class\",\"replace\",\"route\",\"model\"],[\"member-menu-item\",true,\"speeches\",[24,[\"model\",\"id\"]]]],{\"statements\":[[0,\"Anföranden\"]],\"parameters\":[]},null],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"inner-content\"],[8],[0,\"\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n    \\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "riksdagen/templates/member.hbs"
    }
  });

  _exports.default = _default;
});