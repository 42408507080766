define("riksdagen/components/member-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L7yt3pZw",
    "block": "{\"symbols\":[\"bio\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"bio-item\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[0,\"Född: \"],[9],[7,\"span\",true],[8],[1,[24,[\"model\",\"born\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"info\",\"bio\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"bio-item\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[1,[23,1,[\"kod\"]],false],[0,\": \"],[9],[7,\"span\",true],[8],[1,[23,1,[\"uppgift\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"with\",[[24,[\"info\",\"mail\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"bio-item\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"Email: \"],[9],[7,\"span\",true],[8],[1,[24,[\"info\",\"mail\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"with\",[[24,[\"info\",\"webpage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"bio-item\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"Websida:  \"],[9],[7,\"span\",true],[8],[7,\"a\",true],[11,\"href\",[24,[\"info\",\"webpage\"]]],[10,\"target\",\"_BLANK\"],[10,\"class\",\"underline\"],[8],[1,[24,[\"info\",\"webpage\"]],false],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"Kandiderar i nästa val: \"],[1,[24,[\"info\",\"candidateNextElection\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "riksdagen/components/member-info/template.hbs"
    }
  });

  _exports.default = _default;
});