define("riksdagen/components/speech-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    uniqueSpeeches: Ember.computed('model', function () {
      return this.get('model').uniqBy('did').sortBy('date').reverse();
    })
  });

  _exports.default = _default;
});