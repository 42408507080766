define("riksdagen/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute('members'), this.toRoute('member'), this.use('exit-left', {
      duration: 350
    }), this.reverse('exit-right', {
      duration: 350
    }));
  }
});