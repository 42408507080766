define("riksdagen/helpers/material-icon-helper", ["exports", "ember-material-icons/helpers/material-icon-helper"], function (_exports, _materialIconHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _materialIconHelper.default;
    }
  });
  Object.defineProperty(_exports, "materialIconHelper", {
    enumerable: true,
    get: function () {
      return _materialIconHelper.materialIconHelper;
    }
  });
});