define("riksdagen/models/member", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr(),
    lastName: _emberData.default.attr(),
    sortName: _emberData.default.attr(),
    status: _emberData.default.attr(),
    party: _emberData.default.attr(),
    thumbnail: _emberData.default.attr(),
    bigImage: _emberData.default.attr(),
    information: _emberData.default.attr(),
    gender: _emberData.default.attr(),
    born: _emberData.default.attr(),
    stared: false
  });

  _exports.default = _default;
});