define("riksdagen/adapters/vote", ["exports", "ember-data", "xml-js"], function (_exports, _emberData, _xmlJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    host: 'https://data.riksdagen.se',
    namespace: 'voteringlista',
    ajaxOptions: function (url, type, options) {
      const hash = _emberData.default.RESTAdapter.prototype.ajaxOptions.call(this, url, 'GET', options);

      hash.dataType = 'xml';
      return hash;
    },

    handleResponse(status, headers, body) {
      const xml = new XMLSerializer().serializeToString(body);
      return JSON.parse((0, _xmlJs.xml2json)(xml, {
        compact: true,
        textFn: (value, parentElem) => {
          const keyNo = Object.keys(parentElem._parent).length;
          const keyName = Object.keys(parentElem._parent)[keyNo - 1];
          parentElem._parent[keyName] = value;
        }
      }));
    },

    urlForQuery(query) {
      let baseUrl = this.buildURL();
      return `${baseUrl}/?sz=1000&utformat=xml&iid=${query.memberId}`;
    }

  });

  _exports.default = _default;
});