define("riksdagen/routes/member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getTitle = ({
    firstName,
    lastName,
    party
  }, subTitle) => [`${firstName} ${lastName}, ${party}`, subTitle, 'Riksdagskollen'].filter(p => p).join(' | ');

  var _default = Ember.Route.extend({
    model(params) {
      return this.get('store').findRecord('member', params.member_id);
    },

    afterModel: function (member) {
      this.transitionTo('info');
      document.title = getTitle(member);
    },
    actions: {
      loading(transition) {
        let controller = this.controllerFor('application');
        controller.set('currentlyLoading', true);
        transition.promise.finally(function () {
          controller.set('currentlyLoading', false);
        });
      }

    }
  });

  _exports.default = _default;
});