define("riksdagen/serializers/vote", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const model = payload.voteringlista.votering;

      if (Array.isArray(model)) {
        payload = model.map(obj => {
          return this._normalize(obj);
        });
      } else {
        payload = this._normalize(model);
      }

      payload = {
        votes: payload
      };
      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    _normalize(model) {
      return {
        id: model.votering_id,
        vote: model.rost,
        date: model.systemdatum,
        paragraph: model.beteckning,
        point: model.punkt,
        votation: model.votering,
        concern: model.avser,
        link: model.votering_url_xml,
        voteArea: model.valkrets
      };
    }

  });

  _exports.default = _default;
});