define("riksdagen/components/speech-list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    text: null,
    classNames: ['speech-item'],
    actions: {
      openSpeech() {
        if (!this.get('text')) {
          Ember.$.get(`${this.get('model.speechUrl')}/json`.replace('http://', 'https://'), data => {
            this.set('text', data.anforande.anforandetext);
          });
        } else {
          this.set('text', null);
        }
      }

    }
  });

  _exports.default = _default;
});