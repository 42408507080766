define("riksdagen/routes/votes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params, transition) {
      return this.get('store').query('vote', {
        memberId: transition.to.parent.params.member_id
      }).then(data => {
        return data.sortBy('date').reverse();
      });
    }

  });

  _exports.default = _default;
});