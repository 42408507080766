define("riksdagen/components/star-member/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5W0rlTWp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[3,\"action\",[[23,0,[]],\"toggle\"],[[\"bubbles\",\"on\"],[false,[24,[\"touchEnd\"]]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"member\",\"stared\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"material-icon\",[\"star\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"material-icon\",[\"star_border\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "riksdagen/components/star-member/template.hbs"
    }
  });

  _exports.default = _default;
});