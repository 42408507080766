define("riksdagen/adapters/speech", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    host: 'https://data.riksdagen.se',
    namespace: 'anforandelista',

    urlForQuery(query) {
      let baseUrl = this.buildURL();
      return `${baseUrl}/?sz=200&utformat=json&iid=${query.memberId}`;
    }

  });

  _exports.default = _default;
});