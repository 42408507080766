define("riksdagen/components/speech-list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ciSPAuk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"speech-date\"],[8],[1,[24,[\"model\",\"date\"]],false],[9],[0,\"\\n\"],[7,\"h3\",false],[3,\"action\",[[23,0,[]],\"openSpeech\"]],[8],[1,[24,[\"model\",\"title\"]],false],[9],[0,\"\\n\\n\"],[4,\"with\",[[24,[\"text\"]]],null,{\"statements\":[[0,\"  \"],[7,\"section\",true],[10,\"class\",\"speech\"],[8],[1,[22,\"text\"],true],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "riksdagen/components/speech-list-item/template.hbs"
    }
  });

  _exports.default = _default;
});