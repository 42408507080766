define("riksdagen/transitions/move-over-half", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = moveOverHalf;

  function moveOverHalf(dimension, direction, opts) {
    var oldParams = {};
    var newParams = {};
    var overlayParams = {};
    var firstStep;
    var property;
    var measure;
    var elementToOverlay;
    var darkOpacity = 0.75;

    if (direction > 0) {
      // moving right
      overlayParams = {
        opacity: [0, darkOpacity]
      };
      elementToOverlay = this.newElement;
    } else {
      // moving left
      overlayParams = {
        opacity: [darkOpacity, 0]
      };
      elementToOverlay = this.oldElement;
    }

    var overlay = Ember.$('<div class="transition-overlay"></div>');
    elementToOverlay.append(overlay);

    if (dimension.toLowerCase() === 'x') {
      property = 'translateX';
      measure = 'width';
    } else {
      property = 'translateY';
      measure = 'height';
    }

    if ((0, _liquidFire.isAnimating)(this.oldElement, 'moving-in')) {
      firstStep = (0, _liquidFire.finish)(this.oldElement, 'moving-in');
    } else {
      (0, _liquidFire.stop)(this.oldElement);
      firstStep = _liquidFire.Promise.resolve();
    }

    return firstStep.then(() => {
      var bigger = biggestSize(this, measure);
      this.oldElement[0].style.zIndex = direction > 0 ? 5 : 0;
      this.newElement[0].style.zIndex = direction > 0 ? 0 : 5;
      oldParams[property] = bigger * (direction > 0 ? 1 : -0.5) + 'px';
      newParams[property] = ["0px", bigger * (direction > 0 ? -0.5 : 1) + 'px'];
      return _liquidFire.Promise.all([(0, _liquidFire.animate)(overlay, overlayParams, opts), (0, _liquidFire.animate)(this.oldElement, oldParams, opts), (0, _liquidFire.animate)(this.newElement, newParams, opts, 'moving-in')]).then(() => overlay.remove());
    });
  }

  function biggestSize(context, dimension) {
    var sizes = [];

    if (context.newElement) {
      sizes.push(parseInt(context.newElement.css(dimension), 10));
      sizes.push(parseInt(context.newElement.parent().css(dimension), 10));
    }

    if (context.oldElement) {
      sizes.push(parseInt(context.oldElement.css(dimension), 10));
      sizes.push(parseInt(context.oldElement.parent().css(dimension), 10));
    }

    return Math.max.apply(null, sizes);
  }
});