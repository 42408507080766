define("riksdagen/components/member-list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    backgroundImage: Ember.computed('url', function () {
      return Ember.String.htmlSafe(`background: url(${this.get('url')}) no-repeat center center; background-size: cover;`);
    }),

    prepareForForceClick(e) {
      e.preventDefault();
      e.stopPropagation();
    },

    enterForceClick(e) {
      e.preventDefault();
      e.stopPropagation();
      this.toggleProperty('model.stared');
    },

    endForceClick() {},

    forceChanged() {},

    didInsertElement() {
      this._super(...arguments);

      this.element.addEventListener("webkitmouseforcewillbegin", this.prepareForForceClick.bind(this), true);
      this.element.addEventListener("webkitmouseforcedown", this.enterForceClick.bind(this), false);
      this.element.addEventListener("webkitmouseforceup", this.endForceClick.bind(this), false);
      this.element.addEventListener("webkitmouseforcechanged", this.forceChanged.bind(this), false);
    }

  });

  _exports.default = _default;
});