define("riksdagen/components/vote-graph/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VvOjiq+k",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ember-chart\",null,[[\"type\",\"options\",\"data\",\"width\",\"height\"],[\"doughnut\",[24,[\"options\"]],[24,[\"pie\"]],200,200]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "riksdagen/components/vote-graph/template.hbs"
    }
  });

  _exports.default = _default;
});