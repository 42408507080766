define("riksdagen/components/member-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pkuPg5I4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"member-profile-wrapper\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"member-profile-inner\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "riksdagen/components/member-profile/template.hbs"
    }
  });

  _exports.default = _default;
});