define("riksdagen/components/vote-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qA7X1U1x",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"vote-date\"],[8],[1,[24,[\"model\",\"date\"]],false],[9],[0,\"\\n\"],[7,\"h3\",false],[3,\"action\",[[23,0,[]],\"openVote\"]],[8],[1,[24,[\"model\",\"paragraph\"]],false],[0,\":\"],[1,[24,[\"model\",\"point\"]],false],[0,\" - \"],[1,[24,[\"model\",\"vote\"]],false],[9],[0,\"\\n\\n\"],[4,\"with\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"  \"],[7,\"section\",true],[10,\"class\",\"vote\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[8],[1,[22,\"header\"],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[7,\"a\",true],[11,\"href\",[22,\"docLink\"]],[10,\"class\",\"underline\"],[10,\"target\",\"_BLANK\"],[8],[1,[22,\"title\"],false],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n  \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "riksdagen/components/vote-item/template.hbs"
    }
  });

  _exports.default = _default;
});