define("riksdagen/components/member-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    info: Ember.computed('model', function () {
      let info = {
        bio: []
      };
      this.get('model.information').map(data => {
        if (data.typ === 'biografi') {
          info.bio.push(data);
        }

        if (data.kod === 'Officiell e-postadress') {
          info.mail = data.uppgift[0].replace('[på]', '@');
        }

        if (data.kod === 'Webbsida') {
          info.webpage = data.uppgift[0];
        }

        if (data.kod === 'KandiderarINastaVal') {
          if (data.uppgift[0] == "false") {
            info.candidateNextElection = 'Nej';
          } else {
            info.candidateNextElection = 'Ja';
          }
        }
      });
      return info;
    })
  });

  _exports.default = _default;
});