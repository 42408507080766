define("riksdagen/router", ["exports", "riksdagen/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    didTransition() {
      this._super(...arguments);

      window.scrollTo(0, 0);
    }

  });
  Router.map(function () {
    this.route('members', {
      path: '/'
    });
    this.route('member', {
      path: '/members/:member_id'
    }, function () {
      this.route('speeches', {
        resetNamespace: true
      });
      this.route('info', {
        resetNamespace: true
      });
      this.route('votes', {
        resetNamespace: true
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});