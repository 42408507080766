define("riksdagen/components/member-profile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['member-profile'],
    style: Ember.computed('model', {
      get() {
        return Ember.String.htmlSafe(`background: linear-gradient(
        rgba(38, 22, 127, 0.75), 
        rgba(90, 22, 127, 0.45)
      ), url(${Ember.get(this, 'model.bigImage')})  no-repeat center center fixed;`);
      }

    })
  });

  _exports.default = _default;
});